<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:{}
			}
		},
		computed:{
			myParams(){
				let query = Gen.clone(this.$route.query)
				if(this.$route.query.tag) query.tag = this.$route.query.tag
				return query
			},
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_our_blog,
					link: { name: 'Blog' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => { AOS.init() })

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});

			this.getApi()
		},
		methods: {
			getApi() {
				this.$root.topProgress.done()
				Gen.apirest("/blogs",this.myParams,(err,resp)=>{
					if(err) console.log(err)
					this.data = resp.data
				})
			},
			onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			}
		},
		watch: {
			$route(){
				this.getApi()
			}
		}
	};
</script>
<template>
	<div id="content">
		<section class="section nobottommargin notopmargin">
			<div class="container">
				<BreadCrumb :data="breadCrumb"></BreadCrumb>
				<div class="heading-title mb-4">
					<h2>{{web.lbl_our_blog}}</h2>
				</div>
				<div class="row">
					<div class="col-md-6" v-for="(v,k) in data.data" :key="k">
						<div class="blog-item h-353">
							<div class="row">
								<div class="col-sm-5 col-md-12 col-lg-5">
									<div class="bi-thumbnail" style="height: 82%;">
										<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
											<VImg class="img_full" :lazySrc="uploader(v.image, 400)" alt="Image Thumbnail"></VImg>
										</router-link>
									</div>
								</div>
								<div class="col-sm-7 col-md-12 col-lg-7">
									<div class="bi-desc">
										<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
											<h4>{{v.title}}</h4>
											<p v-html="(v.excerpt||'').limitChar(150)"></p>
											<div class="author">
												<span>{{v.author}}</span>
												<span>{{(v.publish_date||"").dates("format")}}</span>
											</div>
											<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}" class="button button-red">{{web.lbl_view}}</router-link>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Pagination class="justify-content-center" :data="data" :limit="4" @pagination-change-page="onPaging"></Pagination>
			</div>
		</section>
	</div>
</template>